import { Splide } from '@splidejs/splide';

class twoImgCarousel extends HTMLElement {
  constructor() {
    super();
    this.main = this.querySelector('#two-img-carousel');
    this.progressBar = this.querySelector('.carousel-progress-bar');
  }

  connectedCallback() {
    this.splideInstance = new Splide(this.main, {
      pagination: false,
      perPage: 1,
      arrows: false,
      gap: '0.5rem'
    });

    this.splideInstance.on('mounted move', () => {
      const end = this.splideInstance.Components.Controller.getEnd() + 1;
      const rate = Math.min((this.splideInstance.index + 1) / end, 1);
      this.progressBar.style.width = `${100 * rate}%`;
    });

    this.splideInstance.mount();
  }

  disconnectedCallback() {
    if (this.splideInstance) {
      this.splideInstance.destroy();
      this.splideInstance = null;
    }
  }
}

customElements.define('two-img-mobile-carousel', twoImgCarousel);
